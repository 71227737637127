/**
 * @typedef {Object} Card
 * @param {Object} params parameters
 * @param {Object[]} params.students related students
 * @param {string[]} params.readingLevels preferred reading levels
 * @param {string[]} params.problemIds preferred problem ids
 * @param {Object[]} params.cards cards to pick suggestions from
 * @param {Object} params.practiceAmounts
 * @param {Object.<string, { count: number, finishedAt: string }>} params.practiceAmounts amount of exercises per card
 * @param {number} params.exerciseExpiration amount of days after when an exercised card may be (re)suggested (default 90)
 * @param {number} params.maxSuggestions max amount of suggested cards (default 3)
 * @param {{ readingLevel: number, linkedProblems: number, notExercised: number, expiredExercise: number, repeatableExercise: number }} params.scores
 * @returns {Card[]} suggested cards
 */
export function getCardSuggestions(params) {
  console.groupCollapsed('get card recommendation', params);

  // remove all reactivity to avoid side effects
  const sanitizedParams = JSON.parse(JSON.stringify(params));

  const {
    // students,
    // readingComprehension,
    readingLevels,
    problemIds,
    cards,
    practiceAmounts = {},
    exerciseExpiration = 30,
    maxSuggestions = 3,
  } = sanitizedParams;

  // overwrite default scores from params
  const scores = Object.assign(
    {},
    DEFAULT_SCORE_VALUES,
    sanitizedParams.scores,
  );

  // calculate accumulated score for every card
  const scoreMap = cards.reduce((acc, card) => {
    const id = card.id;
    if (!(id in acc)) acc[id] = 0;

    acc[id] += getScore(scores).readingLevel(card, readingLevels);
    acc[id] += getScore(scores).linkedProblems(card, problemIds);
    acc[id] += getScore(scores).exercised(
      card,
      practiceAmounts,
      exerciseExpiration,
    );
    return acc;
  }, {});

  // create suggestions...
  const suggestions = cards
    // add scores and assign numbers...
    .map((e) =>
      Object.assign(e, {
        number: parseInt(e.number || '0'),
        _score: scoreMap[e.id],
      }),
    )
    // sort by score, when scores are equal, sort by number (ascending)
    .sort((a, b) => {
      if (a._score === b._score) {
        return a.number - b.number;
      }
      return b._score - a._score;
    })
    // get top x scores
    .slice(0, maxSuggestions);

  console.groupEnd();

  return suggestions;
}

const getScore = (scores) => ({
  readingLevel(card, readingLevels) {
    const score = readingLevels.includes(card.level) ? scores.readingLevel : 0;
    return score;
  },

  linkedProblems(card, problemIds) {
    const hasLinkedProblems = problemIds.reduce((result, problemId) => {
      const match = !!card.linkedProblems?.find(
        (problem) => problem.id === problemId,
      );
      if (match) result = true;
      return result;
    }, false);

    return hasLinkedProblems ? scores.linkedProblems : 0;
  },

  exercised(card, practiceAmounts, exerciseExpiration) {
    return card.id in practiceAmounts
      ? (() => {
          const { count, finishedAt, repeat } = practiceAmounts[card.id];
          if (!count || !finishedAt) return scores.notExercised;
          const dayMS = 1000 * 60 * 60 * 24;
          const now = Date.now();
          const finished = new Date(finishedAt).getTime();
          const daysAgo = (now - finished) / dayMS;
          let score = 0;

          if (count > 0 && daysAgo >= exerciseExpiration) {
            score += scores.expiredExercise;
          }

          if (count > 0 && repeat) {
            score += scores.repeatableExercise;
          }

          if (count === 0) {
            score += scores.notExercised;
          }

          return score;
        })()
      : scores.notExercised;
  },
});

const DEFAULT_SCORE_VALUES = Object.freeze({
  readingLevel: 1.2,
  linkedProblems: 1,
  notExercised: 1.1,
  expiredExercise: 0.55,
  repeatableExercise: 0.55,
});
