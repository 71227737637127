/**
 * Logs changed values of corresponding `keys` in component scope.
 * @param {String[]} keys keys of properties to watch
 * @param {Object} settings watcher settings (applies to all watch keys)
 * @example
 * {
 *   mixins: [ changeLoggerMixin(['myBool', 'myNumber'], { immediate: true }) ],
 *   data() {
 *     return { myBool: true, myNumber: 3 }
 *   }
 * }
 */

export function changeLoggerMixin(keys = [], settings) {
  const watch = {
    watch: Array.from(new Set(keys)).reduce((acc, key) => {
      acc[key] = {
        handler(value) {
          // console.log(
          //   `<${this.$options.name || 'this'}>.${key} changed:`,
          //   JSON.parse(JSON.stringify(value)),
          // );
        },
        immediate: false,
        deep: true,
        ...settings,
      };

      return acc;
    }, {}),
  };

  return watch;
}
