<template>
  <div>
    <sb-loading v-if="$apollo.queries.techniques.loading" />

    <div class="sb_techniques-list">
      <div class="sb_techniques-list_menu">
        <i-menu
          v-if="techniques"
          ref="menu"
          accordion
          width="auto"
          :open-names="openMenuItems"
          :active-name="activeTechniqueId"
          @on-select="activeTechniqueId = $event"
          @on-open-change="handleMenuOpenChange"
        >
          <submenu
            v-for="parent in techniques"
            :key="parent.id"
            :ref="['parent', parent.id].join(':')"
            :name="parent.id"
          >
            <template #title>
              <div class="sb_techniques-list_menu_title">
                {{ `${parent.number}. ${parent.title}` }}
              </div>
            </template>
            <menu-item
              v-for="child in parent.children"
              :key="child.id"
              :name="child.id"
              class="sb_techniques-list_menu_item"
              :data-child-number="child.number"
              :data-child-technique-id="child.id"
            >
              <div class="sb_techniques-list_menu_item_no">
                {{ child.number }}
              </div>
              <div class="sb_techniques-list_menu_item_title">
                {{ child.title }}
              </div>
            </menu-item>
          </submenu>
        </i-menu>
      </div>

      <div class="sb_techniques-list_content">
        <template v-if="activeTechnique">
          <h2>{{ activeTechnique.number }} {{ activeTechnique.title }}</h2>
          <div v-html="activeTechnique.content" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import SbLoading from '@/components/SbLoading.vue';
export default {
  name: 'SbTechniquesList',
  components: { SbLoading },

  props: {
    syncWithQueryParam: { type: Boolean, default: false },
  },

  data() {
    return { activeTechniqueId: this.$route.query.techniqueId };
  },

  computed: {
    activeTechnique() {
      return this.findTechniqueById(this.activeTechniqueId);
    },

    openMenuItems() {
      const techniqueId = this.$route.query.techniqueId;

      if (!this.techniques?.length || !techniqueId) return [];

      for (const parent of this.techniques) {
        if (parent.id === techniqueId) return [techniqueId];
        for (const child of parent.children) {
          if (child.id === techniqueId) return [parent.id];
        }
      }

      return [];
    },
  },

  watch: {
    '$route.query.techniqueId'(value) {
      const button = document.querySelector(
        `[data-child-technique-id="${value}"]`,
      );

      if (button) {
        button.click();
      } else this.activeTechniqueId = value;
    },

    activeTechniqueId(value) {
      if (!this.syncWithQueryParam) return;
      this.$router
        .replace({
          name: this.$route.name,
          query: { ...this.$route.query, techniqueId: value },
        })
        .catch((_) => null);
    },

    activeTechnique() {
      setTimeout(() => {
        this.$scrollTo({ top: 0 }, 500);
      }, 200);
    },
  },

  methods: {
    handleMenuOpenChange(event) {
      const id = event[0];
      this.activeTechniqueId = id;
      if (!id) this.$refs.menu.currentActiveName = undefined;
    },

    findTechniqueById(id) {
      if (!this.techniques) return;
      for (const technique of this.techniques) {
        if (technique.id === id) return technique;
        if (!technique.children) continue;
        for (const child of technique.children) {
          if (child.id === id) return child;
        }
      }
    },

    parseTechniqueNode(node) {
      const { id, title, content, number } = node;

      const children = node.children?.edges.map(({ node }) =>
        this.parseTechniqueNode(node),
      );

      return { id, title, content, number, children };
    },
  },

  apollo: {
    techniques: {
      query: gql`
        query Techniques {
          techniques(first: 1000) {
            edges {
              node {
                id
                title
                number
                content
                order
                parentId
              }
            }
          }
        }
      `,

      update(data) {
        /**
         * We get all techniques in a one-dimensional array, while they actually have parent/child structure.
         * This code assigns children to their parent node in as little iterations as possible, because there is potentially a lot of data to process so we should avoid exponential iteration cycles.
         */

        const parents = [];
        const children = [];
        const output = [];
        let parent;

        // derive parent & child nodes from response data (node is child when it has a parent)
        data.techniques.edges.forEach(({ node }) =>
          node.parentId
            ? children.push(node)
            : parents.push({ ...node, children: { edges: [] } }),
        );

        // while there are parents left to process...
        while (parents.length) {
          // pick the last parent node
          parent = parents.pop();

          // check each leftover child for parent id match
          for (let i = 0; i < children.length; i++) {
            if (children[i].parentId !== parent.id) continue;
            // pick the matching child and move it to parent `children`
            parent.children.edges.push({ node: children.splice(i, 1)[0] });
            // reset iterations
            i = -1;
          }

          // parse the parent technique node and add it to output
          output.push(this.parseTechniqueNode(parent));
        }

        this.activeTechniqueId = this.$route.query.techniqueId || output[0]?.id;

        // sort parent and child techniques based on number
        output.sort((a, b) => (a.number < b.number ? -1 : 1));
        output.forEach((list) =>
          list.children.sort((a, b) =>
            parseInt(a.number.split('.').pop()) <
            parseInt(b.number.split('.').pop())
              ? -1
              : 1,
          ),
        );

        return output;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_techniques-list {
  display: flex;
  padding: 60px 0 30vh 0;
  white-space: initial !important;

  h1,
  h2 {
    margin-bottom: 30px;
  }

  &_menu {
    width: 420px;
    padding-right: 60px;

    &_title {
      padding-right: 30px;
    }

    &_item {
      display: flex;
      &_no {
        flex: 0 0 50px;
      }
      &_text {
      }
    }

    .ivu-menu {
      &-item,
      &-submenu {
        border-top: 1px solid $brand-light-gray;

        .ivu-menu-item {
          color: $brand-gray;
        }
      }

      &-item {
        transition: color 0.3s ease-out;

        &:hover {
          color: $brand-black;
          transition-duration: 0s;
        }
      }

      &-submenu:last-child {
        border-bottom: 1px solid $brand-light-gray;
      }

      &-item-selected {
        font-weight: 700;
        background: $brand-white !important;
        color: $brand-primary !important;

        &::after {
          display: none !important;
        }
      }

      &::after {
        display: none;
      }
    }
  }

  &_content {
    flex: 1;
    max-width: rem-calc(800);
  }
}
</style>

<style lang="scss">
.sb_techniques-list_content {
  ol,
  ul {
    padding-left: 1.4rem;
  }
}
</style>
