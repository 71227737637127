<template>
  <transition v-if="show" name="fade">
    <div
      class="sb_loading"
      :style="{
        width,
        height,
        position,
      }"
    >
      <sb-loading-spinner size="3rem" color="#F58234" border-width=".2rem" />
    </div>
  </transition>
</template>

<script>
import SbLoadingSpinner from '@/components/SbLoadingSpinner';

export default {
  name: 'SbLoading',

  components: {
    SbLoadingSpinner,
  },

  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100vh',
    },
    position: {
      type: String,
      default: 'fixed',
    },
    delay: { type: Number, default: 300 },
  },

  data: () => ({ timeout: undefined, show: false }),

  mounted() {
    this.timeout = setTimeout(() => {
      this.show = true;
    }, this.delay);
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss" scoped>
.sb_loading {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: rgba($brand-white, 0.5);
  color: $brand-primary;
}
</style>
