<template>
  <div>
    <div v-if="cardRecommendations.length">
      <sb-divider title="Suggesties" top-space="20px" />
      <p class="sb_suggestion-notice">
        We hebben de volgende suggesties klaargezet op basis van niveau,
        problematieken en eerdere sessies.
      </p>

      <p class="sb_suggestion-notice">
        Je kan ook een andere kaart kiezen uit "Alle kaarten" hieronder.
      </p>
      <div class="sb_suggestions-container">
        <div
          v-for="card in cardRecommendations"
          :key="card.id"
          class="sb_suggestion-card"
          @click="handleSuggestionSelect(card.id)"
        >
          <div class="sb_suggestion-card_number">#{{ card.number || '?' }}</div>
          <div class="sb_suggestion-card_title">
            {{ `${card.title}` }}
          </div>
          <div class="sb_suggestion-card_level-type">
            <span class="sb_suggestion-card_level"
              ><b>{{ card.level }}</b></span
            >
            <span class="sb_suggestion-card_type">{{ card.type }}</span>
          </div>
          <div class="sb_suggestion-card_methods">
            <span
              v-if="get(card, 'methodologies', 0)"
              class="sb_suggestion-card_method"
            >
              {{ get(card, 'methodologies', 0) }}
            </span>
          </div>
        </div>
      </div>
      <sb-divider title="Alle kaarten" />
    </div>
  </div>
</template>

<script>
import { getCardSuggestions } from '@/lib/get-card-recommendations';
import { readingLevelMixin } from '@/lib/reading-level';
import { changeLoggerMixin } from '@/mixins/changeLogger';

export default {
  name: 'SbCardsSuggestions',

  inject: ['students', 'getParentView'],
  mixins: [readingLevelMixin, changeLoggerMixin([])],

  props: {
    cards: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      cardFilters: undefined,
    };
  },

  computed: {
    studentIds() {
      return this.$route.query.studentIds?.split(',') ?? [];
    },

    cardLevels() {
      return Array.from(
        new Set(
          this.students
            ?.map((student) =>
              student.tracks.edges.map(({ node }) => node.readingLevels),
            )
            .flat() ?? [],
        ),
      );
    },

    cardRecommendationParams() {
      const students = this.students;
      const problemIds = [this.$route.query.problemId].filter(Boolean) ?? [];
      const readingLevels = this.cardLevels.flatMap((level) => level);
      const readingComprehension =
        this.cardFilters?.readingComprehension ?? false;

      const cards = readingComprehension
        ? this.cards?.filter((card) => !!card.addReadingComprehension)
        : this.cards;

      if (!students?.length || !cards?.length || !readingLevels?.length) {
        // console.log(
        //   `return null because of missing params: ${[
        //     !students.length ? 'students' : null,
        //     !cards.length ? 'cards' : null,
        //     !readingLevels.length ? 'readingLevels' : null,
        //   ]
        //     .filter(Boolean)
        //     .join(', ')}`,
        // );
        return null;
      }

      return {
        students,
        cards,
        problemIds,
        readingLevels,
        practiceAmounts: this.practiceAmountLookup,
      };
    },

    cardRecommendations() {
      const params = this.cardRecommendationParams;
      if (!params) return [];

      return getCardSuggestions(params);
    },
  },

  methods: {
    handleSuggestionSelect(cardId) {
      // emit event to parent
      this.cardId = cardId;
      this.$emit('card-select', cardId);
    },
  },
};
</script>

<style lang="scss">
.ivu-btn-text {
  &:hover {
    color: $brand-black !important;
  }
}
</style>

<style lang="scss" scoped>
.sb_suggestion-notice {
  margin-bottom: 0em !important;
  color: $brand-dark-gray;
}

.sb_suggestion-card {
  display: block;
  margin-block: 1rem;
}

.sb_cards-session_training-select {
  overflow: auto;

  &_inner {
    padding: 0 2rem;
  }

  &_card-row {
    min-height: 70px;
    cursor: pointer;
  }

  &_card-number {
    display: inline-block;
    padding: 7px 5px 5px;
    background: $brand-primary;
    border-radius: $default-border-radius;
    color: $brand-white;
  }
}

.sb_suggestions-container {
  display: grid;
  grid-template-columns: repeat(3, 300px);
  gap: 1.5rem;
}

.sb_suggestion-card {
  width: 300px;
  padding: 1rem;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid $brand-light-gray;
  box-sizing: border-box;
  box-shadow: 0 0 8px rgba($brand-primary-darkest, 0.1);
  cursor: pointer;
  transition: 0.3s background-color;

  &:hover {
    background: $brand-primary-lightest;
    transition-duration: 0s;
  }

  &_number {
    font-size: 0.9rem;
    font-weight: 600;
  }

  &_title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 800;
  }

  &_level-type {
    margin: 0.5rem 0;
    span {
      margin-right: 0.5rem;
    }
  }

  &_methods {
    display: flex;
    gap: 0.5rem;
    overflow: auto;
    white-space: nowrap;
    margin: 0 -1rem;
    padding: 0 1rem;

    // hide scroll bar but allow scrolling
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_method {
    display: inline-block;
    font-size: 0.9rem;
    background: rgba($brand-primary, 0.5);
    color: white;
    padding: 2px 8px;
    border-radius: 1em;
  }
}
</style>
