/**
 * Create non-reactive members on a Vue component
 * @param {Function} input function that returns an object. This object is assigned to the component instance as static members.
 * @param {Function} callback invoked after the `input` is assigned to the component.
 * @returns mixin
 */
export function nonReactiveMembersMixin(input, callback) {
  return {
    created() {
      Object.entries(input(this)).forEach(
        ([key, value]) => (this[key] = value),
      );
      callback?.(this);
    },
  };
}
